import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArticleUtilsService {
    timingsTagIds = [
        'timingAnytime',
        'timingDay6190',
        'timingDay3160',
        'timingDay830',
        'timingDay27',
        'timingDay11',
      ];
    
      ageRangeInMonthsCat: { [key: string]: [number, number] } = {
        speciesCatSuperSenior: [180, 500],
        speciesCatSenior: [132, 179],
        speciesCatMature: [84, 131],
        speciesCatAdult: [13, 83],
        speciesCatJunior: [5, 12],
        speciesCatKitten: [0, 4],
        speciesCatAll: [0, 500],
      };
    
      ageRangeInMonthsDog: { [key: string]: [number, number] } = {
        speciesDogSuperSenior: [145, 500],
        speciesDogSenior: [96, 144],
        speciesDogMature: [60, 95],
        speciesDogAdult: [13, 59],
        speciesDogJunior: [5, 12],
        speciesDogPuppy: [0, 4],
        speciesDogAll: [0, 500],
      };
    
      ageRanges: { [key: string]: { [key: string]: [number, number] } } = {
        Cat: this.ageRangeInMonthsCat,
        Dog: this.ageRangeInMonthsDog,
      };
  constructor() { }

  getCategoriesByAgeAndType(
    months: number,
    animalType: 'Cat' | 'Dog'
  ): string[] {
    let ageRanges: { [key: string]: [number, number] };
    if (animalType === 'Cat') {
      ageRanges = {
        ...this.ageRangeInMonthsCat,
        // speciesCatAll: [0, 500],
      };
    } else {
      ageRanges = {
        ...this.ageRangeInMonthsDog,
      };
    }

    const applicableCategories = Object.keys(ageRanges).filter(
      (category) =>
        months >= ageRanges[category][0] && months <= ageRanges[category][1]
    );

    return applicableCategories;
  }

  calculatePetAgeInMonths(birthday: any) {
    const current = new Date();
    const birthdayObject = new Date(birthday);
    return (
      (current.getFullYear() - birthdayObject.getFullYear()) * 12 +
      current.getMonth() -
      birthdayObject.getMonth()
    );
  }

  getTimingTagByDays(days: number): string {
    if (days >= 0 && days <= 1) {
      return 'timingDay11';
    } else if (days >= 2 && days <= 7) {
      return 'timingDay27';
    } else if (days >= 8 && days <= 30) {
      return 'timingDay830';
    } else if (days >= 31 && days <= 60) {
      return 'timingDay3160';
    } else if (days >= 61 && days <= 90) {
      return 'timingDay6190';
    } else {
      return 'timingAnytime';
    }
  }
}
