<div
  class="row padding print-section"
  *ngIf="!isLoadingContentfulData && contentfulEntryData"
>
  <div class="col-md-12 article-container">
    <div class="row d-flex justify-content-center">
      <div
        class="col-md-12 d-flex justify-content-center article-img-container"
      >
        <h1
          class="bottom-left h1 title-background"
          *ngIf="contentfulEntryData?.title"
        >
          {{ contentfulEntryData?.title }}
        </h1>
        <img
          class="boxer-jumbotron"
          [src]="
            'https:' + contentfulEntryData?.articleHeroImageSimple?.file.url
          "
          [alt]="contentfulEntryData?.articleHeroImageSimple?.description || ''"
          *ngIf="contentfulEntryData?.articleHeroImageSimple?.file"
        />
      </div>

      <div class="col-lg-9 col-12 px-4">
        <div class="d-flex justify-content-between align-items-center">
          <h3
            class="subtitle mt-3 p-italic"
            *ngIf="contentfulEntryData?.subtitle"
          >
            {{ contentfulEntryData?.subtitle }}
          </h3>
          <i class="bi bi-printer print-button" (click)="printPage()"></i>
        </div>
        <div class="row">
          <div class="col-12">
            <div
              class="row d-flex flex-row flex-nowrap align-items-center"
              *ngIf="contentfulEntryData?.articleAuthor?.publicauthorName"
            >
              <div
                class="orange-box"
                *ngIf="
                  !contentfulEntryData?.articleAuthor?.authorphotoSimple?.file
                    .url
                "
              >
                <span class="white-letter">M</span>
              </div>
              <div class="author-box" style="width: 46px">
                <img
                  class="author-box"
                  [src]="
                    'https:' +
                    contentfulEntryData?.articleAuthor?.authorphotoSimple?.file
                      .url
                  "
                />
              </div>
              <h6 class="mt-3">
                Author
                <strong
                  ><span>{{
                    contentfulEntryData?.articleAuthor?.publicauthorName
                  }}</span></strong
                >
              </h6>
            </div>
            <div #bodyHtmlContainer class="mt-3 articles" *ngIf="bodyHtml">
              <div class="articles" [innerHTML]="getSafeHtml()"></div>
            </div>

            <ng-container
              *ngIf="disabledBrands.length === 0 || filteredCTAs.length > 0"
            >
              <!-- Display CTAs if no exclusive brands or if there are matching CTAs -->
              <div class="mt-3">
                <ng-container *ngFor="let cta of filteredCTAs; let i = index">
                  <div class="cta-card p-2 mb-3">
                    <!-- Image -->
                    <img
                      class="cta-image"
                      [src]="
                        cta?.ctaImageSimple?.file.url ||
                        '//default-image-url.png'
                      "
                      [alt]="cta?.ctaImageSimple?.description || ''"
                    />

                    <!-- Text -->
                    <div class="row text-center">
                      <div
                        class="cta-text p-3"
                        [innerHTML]="cta?.ctaTextHtml || ''"
                      ></div>

                      <!-- Button -->
                      <a
                        class="cta-button m-auto"
                        [href]="cta?.ctaUrl"
                        target="_blank"
                      >
                        {{ cta?.ctaButton.ctaTextButton || "" }}
                      </a>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <ng-container
              *ngIf="
                disabledBrands.length === 0 || filteredProductCTAs.length > 0
              "
            >
              <div class="mt-3">
                <ng-container *ngFor="let cta of filteredProductCTAs">
                  <div class="cta-card p-2 mb-3">
                    <!-- Image -->
                    <img
                      class="cta-image"
                      [src]="
                        cta?.ctaImageSimple?.file.url ||
                        '//default-image-url.png'
                      "
                      [alt]="cta?.ctaImageSimple?.description || ''"
                    />

                    <!-- Text -->
                    <div class="row text-center">
                      <div
                        class="cta-text p-3"
                        [innerHTML]="cta?.ctaTextHtml || ''"
                      ></div>

                      <!-- Buttons -->
                      <div class="cta-buttons">
                        <a
                          *ngIf="cta.ctaButton1"
                          class="cta-button m-1"
                          [href]="cta.ctaUrl1"
                          target="_blank"
                        >
                          {{ cta.ctaButton1.ctaTextButton || "" }}
                        </a>
                        <a
                          *ngIf="cta.ctaButton2"
                          class="cta-button m-1"
                          [href]="cta.ctaUrl2"
                          target="_blank"
                        >
                          {{ cta.ctaButton2.ctaTextButton || "" }}
                        </a>
                        <a
                          *ngIf="cta.ctaButton3"
                          class="cta-button m-1"
                          [href]="cta.ctaUrl3"
                          target="_blank"
                        >
                          {{ cta.ctaButton3.ctaTextButton || "" }}
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <div class="mt-5 mb-3" *ngIf="cardsData.length > 0">
              <div class="relevant-font">Relevant Content</div>
            </div>

            <div class="row relevant-content" *ngIf="cardsData.length > 0">
              <div
                class="col-4 relevant-content-col"
                *ngFor="let card of cardsData">
                <a
                  [routerLink]="
                    card.type === 'article'
                      ? '/articles/' + card.slug
                      : '/videos/' + card.slug
                  "
                >
                  <div class="relevant-card text-center">
                    <img
                      *ngIf="card.type === 'article'"
                      [src]="card.image"
                      class="card-img-content"
                      alt="{{ card.title }}"
                    />
                    <div class="card-img-wrapper position-relative" *ngIf="card.type === 'video'">
                      <img [src]="card.videoThumbnail" class="card-img-content" style="background-color: black;"/>

                      <!-- Modify or remove the play icon as needed -->
                      <i class="bi bi-play play-icon" title="Play Video"></i>
                    </div>

                    <div class="relevant-card-body">
                      <p
                        class="relevant-card-title"
                        [ngClass]="{
                          'read-article': card.type === 'article',
                          'watch-video': card.type === 'video'
                        }"
                      >
                        {{
                          card.type === "article"
                            ? "Read Article"
                            : "Watch Video"
                        }}
                      </p>
                      <span class="relevant-card-text lh-base">
                        <h5 class="card-title">{{ card.title }}</h5>
                        <!-- Optional: Display subtitle or author name if you wish -->
                        <!-- <p class="card-subtitle">{{ card.subtitle }}</p> -->
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="showErrorBanner"
  class="text-center h-100 w-100 d-flex align-items-center justify-content-center"
>
  <div class="alert alert-danger mb-5">
    An error has occurred, please try again. If the issue persists, please
    contact <a href="mailto:support@petszel.com">support&#64;petszel.com</a>.
  </div>
</div>


<ng-container *ngIf="isLoadingContentfulData">
  <lib-loading></lib-loading>
</ng-container>
